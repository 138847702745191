import { copyrightText, footerNavigation } from "@/constants";

const MinimalFooter = () => {
    return (
        <footer className="bg-white border-t shadow footer">
            <div className="mx-auto max-w-7xl py-4 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-6 print:flex print:items-center print:justify-between print:break-before-auto print:break-after-auto print:break-inside-avoid">
                <div className="flex justify-center space-x-6 md:order-2 print:order-2">
                    {footerNavigation.social
                        .filter(({ active }) => active)
                        .map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className="text-gray-700 hover:text-gray-500"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span className="sr-only">{item.name}</span>
                                <item.icon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </a>
                        ))}
                </div>
                <div className="mt-8 md:order-1 md:mt-0 lg:ml-5 print:order-1 print:mt-0">
                    <p className="text-center text-sm text-gray-700">
                        &copy; {copyrightText}
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default MinimalFooter;
